import React, { useEffect, useState } from 'react'
import {
  Button,
  ConfirmationButton,
  Container,
  ContainerBox,
  CopyButton,
  Loader,
  Modal,
  Table,
} from 'components'
import { Box, Flex, Heading, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { ResponseMerchant } from 'types/merchant'
import { useApi } from 'hooks/useApi'
import { useParams } from 'react-router-dom'
import ManagerForm from './components/ManagerForm'
import { getShortString } from 'utils/formatters'
import CardFeeForm from './components/CardFeeForm'
import { ResponseManager } from 'types/manager'
import { ResponseCardFee } from 'types/card'
import { ResponseUser } from 'types/user'
import { MerchantStatistics } from 'types/statistics'

const MerchantPage = () => {
  const { id: merchantId } = useParams()
  const {
    getMerchant,
    disableManager,
    enableManager,
    disableCardFee,
    enableCardFee,
    disableUser,
    enableUser,
    getMerchantStatistics,
  } = useApi()
  const {
    isOpen: isManagerModalOpen,
    onOpen: onManagerModalOpen,
    onClose: onManagerModalClose,
  } = useDisclosure()
  const {
    isOpen: isCardFeeModalOpen,
    onOpen: onCardFeeModalOpen,
    onClose: onCardFeeModalClose,
  } = useDisclosure()
  const [merchant, setMerchant] = useState<ResponseMerchant>()
  const [merchantStatistics, setMerchantStatistics] = useState<MerchantStatistics>()
  const [editedManager, setEditedManager] = useState<ResponseManager>()
  const [editedCardFee, setEditedCardFee] = useState<ResponseCardFee>()
  const [isLoading, setIsLoading] = useState(true)

  const refreshMerchant = async () => {
    if (!merchantId) {
      return
    }
    setIsLoading(true)
    const merchantResult = await getMerchant(merchantId)
    const merchantStatisticsResult = await getMerchantStatistics(merchantId)
    setMerchant(merchantResult)
    setMerchantStatistics(merchantStatisticsResult)
    setIsLoading(false)
  }

  const handleCreateManager = () => {
    setEditedManager(undefined)
    onManagerModalOpen()
  }

  const handleEditManager = (manager: ResponseManager) => {
    setEditedManager(manager)
    onManagerModalOpen()
  }

  const handleDisableManager = async (id: string) => {
    setIsLoading(true)
    await disableManager(id)
    refreshMerchant()
  }

  const handleEnableManager = async (id: string) => {
    setIsLoading(true)
    await enableManager(id)
    refreshMerchant()
  }

  const handleCreateCardFee = () => {
    setEditedCardFee(undefined)
    onCardFeeModalOpen()
  }

  const handleEditCardFee = (cardFee: ResponseCardFee) => {
    setEditedCardFee(cardFee)
    onCardFeeModalOpen()
  }

  const handleDisableCardFee = async (id: string) => {
    setIsLoading(true)
    await disableCardFee(id)
    refreshMerchant()
  }

  const handleEnableCardFee = async (id: string) => {
    setIsLoading(true)
    await enableCardFee(id)
    refreshMerchant()
  }

  const handleDisableUser = async (id: string) => {
    setIsLoading(true)
    await disableUser(id)
    refreshMerchant()
  }

  const handleEnableUser = async (id: string) => {
    setIsLoading(true)
    await enableUser(id)
    refreshMerchant()
  }

  useEffect(() => {
    refreshMerchant()
  }, [])

  const statistics = [
    { label: 'Total Users', render: (ms: MerchantStatistics) => ms.totalUsers },
    { label: 'Total Cards', render: (ms: MerchantStatistics) => ms.totalCards },
    { label: 'Activated Cards', render: (ms: MerchantStatistics) => ms.activatedCards },
  ]

  return (
    <Container mt={12}>
      {isLoading && <Loader />}
      {!isLoading && merchant && merchantStatistics && (
        <>
          <Box>Merchant</Box>
          <Heading>{merchant.companyName}</Heading>
          <SimpleGrid columns={{ base: 1, lg: 3 }} gap={3} mt={6}>
            {statistics.map(s => (
              <ContainerBox>
                <Box>{s.label}</Box>
                <Heading>{s.render(merchantStatistics)}</Heading>
              </ContainerBox>
            ))}
          </SimpleGrid>
          <ContainerBox mt={6}>
            <Flex justify="space-between" mb={6} gap={3}>
              <Heading size="lg">Managers</Heading>
              <Button onClick={handleCreateManager}>Add</Button>
            </Flex>
            {merchant.managers && merchant.managers.length === 0 && (
              <Box>No managers to display</Box>
            )}
            {merchant.managers && merchant.managers.length > 0 && (
              <Table
                data={merchant.managers.sort((a, b) => (a.isDisabled && !b.isDisabled ? 1 : -1))}
                columns={[
                  {
                    title: 'Name',
                    render: m => `${m.name} ${m.surname}`,
                  },
                  {
                    title: 'Address',
                    render: m => <CopyButton text={getShortString(m.address)} value={m.address} />,
                  },
                  {
                    title: 'Email',
                    render: m => m.email,
                  },
                  {
                    title: 'Actions',
                    render: (m: ResponseManager) => (
                      <Flex gap={6}>
                        <Button onClick={() => handleEditManager(m)} variant="link">
                          Edit
                        </Button>
                        {m.isDisabled && (
                          <Button
                            onClick={() => handleEnableManager(m.id)}
                            variant="link"
                            colorScheme="green"
                          >
                            Enable
                          </Button>
                        )}
                        {!m.isDisabled && (
                          <ConfirmationButton
                            onConfirm={() => handleDisableManager(m.id)}
                            modalContent="Are you sure you want to disable this manager? The manager will not be able to access the system."
                            variant="link"
                            colorScheme="red"
                          >
                            Disable
                          </ConfirmationButton>
                        )}
                      </Flex>
                    ),
                  },
                ]}
              />
            )}
          </ContainerBox>

          <ContainerBox mt={6}>
            <Flex justify="space-between" mb={6} gap={3}>
              <Heading size="lg">Card Types</Heading>
              <Button onClick={handleCreateCardFee}>Add</Button>
            </Flex>
            {merchant && merchant.cardFees?.length === 0 && <Box>No card types to display</Box>}
            {merchant.cardFees && merchant.cardFees.length > 0 && (
              <Table
                data={merchant.cardFees.sort((a, b) => (a.isDisabled && !b.isDisabled ? 1 : -1))}
                columns={[
                  {
                    title: 'Card Type',
                    render: cf => `${cf.cardType.name} (${cf.cardType.providerCardTypeId})`,
                  },
                  {
                    title: 'Application Fee',
                    render: cf => `${cf.applicationFeeUsd / 100} USD`,
                  },
                  {
                    title: 'Recharge Fee',
                    render: cf => `${cf.rechargeFeePercent}%`,
                  },
                  {
                    title: 'Actions',
                    render: (cf: ResponseCardFee) => (
                      <Flex gap={6}>
                        <Button onClick={() => handleEditCardFee(cf)} variant="link">
                          Edit
                        </Button>
                        {cf.isDisabled && (
                          <Button
                            onClick={() => handleEnableCardFee(cf.id)}
                            variant="link"
                            colorScheme="green"
                          >
                            Enable
                          </Button>
                        )}
                        {!cf.isDisabled && (
                          <ConfirmationButton
                            onConfirm={() => handleDisableCardFee(cf.id)}
                            modalContent="Are you sure you want to disable this card type? The merchant will not be able to use this card type."
                            variant="link"
                            colorScheme="red"
                          >
                            Disable
                          </ConfirmationButton>
                        )}
                      </Flex>
                    ),
                  },
                ]}
              />
            )}
          </ContainerBox>

          <ContainerBox mt={6}>
            <Heading size="lg" mb={6}>
              Users
            </Heading>
            {isLoading && <Loader />}
            {!isLoading && merchant && merchant.cardFees?.length === 0 && (
              <Box>No users to display</Box>
            )}
            {!isLoading && merchant.users && merchant.users.length > 0 && (
              <Table
                columns={[
                  {
                    title: 'Name',
                    render: u => (
                      <Button
                        variant="link"
                        to={`/users/${u.id}`}
                        opacity={u.isDisabled ? 0.6 : undefined}
                      >
                        {u.name} {u.surname}
                      </Button>
                    ),
                  },
                  {
                    title: 'ID',
                    render: u => <CopyButton text={getShortString(u.id)} value={u.id} />,
                  },
                  {
                    title: 'Address',
                    render: u => <CopyButton text={getShortString(u.address)} value={u.address} />,
                  },
                  {
                    title: 'Actions',
                    render: (u: ResponseUser) => (
                      <Flex gap={6}>
                        {u.isDisabled && (
                          <Button
                            onClick={() => handleEnableUser(u.id)}
                            variant="link"
                            colorScheme="green"
                          >
                            Enable
                          </Button>
                        )}
                        {!u.isDisabled && (
                          <ConfirmationButton
                            onConfirm={() => handleDisableUser(u.id)}
                            modalContent="Are you sure you want to disable this user?"
                            variant="link"
                            colorScheme="red"
                          >
                            Disable
                          </ConfirmationButton>
                        )}
                      </Flex>
                    ),
                  },
                ]}
                data={merchant.users}
              />
            )}
          </ContainerBox>

          <Modal title="New Manager" isOpen={isManagerModalOpen} onClose={onManagerModalClose}>
            <ManagerForm
              existingManager={editedManager}
              merchantId={merchant.id}
              onSuccess={() => {
                onManagerModalClose()
                refreshMerchant()
              }}
            />
          </Modal>
          <Modal title="Assign Card Type" isOpen={isCardFeeModalOpen} onClose={onCardFeeModalClose}>
            <CardFeeForm
              existingCardFee={editedCardFee}
              onSuccess={() => {
                onCardFeeModalClose()
                refreshMerchant()
              }}
              merchantId={merchant.id}
            />
          </Modal>
        </>
      )}
    </Container>
  )
}

export default MerchantPage
